import React from 'react'
import FormDropDown from '../FormDropDown/FormDropDown';
import { useSelector } from 'react-redux';

const LandploatFormStages = ({ stagesData, stagesOption }) => {
    const microdistData = useSelector((state) => state.mocrodist_data);
    return (
        <div>
            {stagesData?.map((item, index) => {
                return <FormDropDown number={index + 1} dataDrop={item} dataOption={stagesOption}/>
            })}

        </div>
    )
}

export default LandploatFormStages