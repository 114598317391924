import React, { Fragment, useEffect, useState } from 'react';
import './styles.scss';
import FormInput from '../FormInput/FormInput';
import Buttons from '../Buttons/Buttons';
import icons from '../../assets/icons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { clearDataArrayMicrodistKey, clearDataMicrodist, clearDataMicrodistKey, setDataPressItem } from '../../store/microdistDataReduces';
import { useHistory, useParams } from 'react-router-dom';
import { fieldToArray, transformDate } from '../UI/functions/functions';
import ErrorMessage from '../UI/ErrorMassage/ErrorMassage';
import groupUser from '../../locales/groupUser';
import ConstructionReportApiRequest from '../../api/ConstructionReport/ConstructionReport';

const ReportContain = ({ data }) => {

    const userData = useSelector(state => state.user_data.userData);
    const isAdmin = userData?.user?.groups?.some(group => group.name === groupUser.admin);
    const isControlBuild = userData?.user?.groups?.some(group => group.name === groupUser.controlBuild);
    const isPaymentDepartment = userData?.user?.groups?.some(group => group.name === groupUser.PaymentDeportament);

    const dispatch = useDispatch();
    const [dataOption, setOptions] = useState([]);
    const [isErr, setIsErr] = useState(false);
    const reportData = useSelector((state) => state.mocrodist_data);

    const constructionReport = new ConstructionReportApiRequest();
    const [fields, setFields] = useState([
        { key: `fields_1`, value: { label: `Поле 1`, type: 'text', required: false }}
    ]);

    const handleAddField = () => {
        setFields(prevFields => [...prevFields, { key: `fields_${fields.length}`, value: { label: `Поле ${fields.length + 1}`, type: 'text', required: false } }]);
    };

    const history = useHistory();
    const { id } = useParams();

    
    useEffect(() => {
        constructionReport.options().then((resp) => {
            const options = fieldToArray(resp.data.actions.create);
            setOptions(options);
        });
       
    }, []);


    useEffect(() => {
        const dataField = fieldToArray(data);
        dataField?.forEach((item) => {
            dispatch(setDataPressItem([item.key, item.value]));
        });
    }, [data, dispatch]);


  

    const handleChangeInput = (field, value) => {
        if (field === 'fields') {
            // Если reportData.fields ещё не является массивом, создайте новый массив и добавьте в него значение
            const newFields = Array.isArray(reportData.fields) ? [...reportData.fields, value] : [value];
            dispatch(setDataPressItem([field, newFields]));
        } else {
            // В противном случае, обновите другие поля:
            dispatch(setDataPressItem([field, value]));
        }
    };
    
    const handleRemoveField = (index) => {
        const newFields = [...fields];
        newFields.splice(index, 1);
        setFields(newFields);
        dispatch(clearDataArrayMicrodistKey(index));
    };


    const saveReport = () => {
        let hasError = false;

        dataOption.forEach((item) => {
            if (item.value.required && (!reportData[item.key] || reportData[item.key].length === 0)) {
                hasError = true;
            }
        });

        if (hasError) {
            setIsErr("Заполните все обязательные поля.");
        } else {
            constructionReport.create({ body: reportData }).then((resp) => {
                if (resp.success) {
                    dispatch(clearDataMicrodist());
                    history.push('/construction-report');
                } else {
                    setIsErr(resp.message);
                }
            }).catch((item) => {
                setIsErr(item.message);
            });
        }
    }



    useEffect(() => {
        if (id === undefined) {
            dispatch(clearDataMicrodist());
        }
    }, [id]);

   
    const dataFields = {
        user_created: ['user_created'],
    };

    const renderedKeys = [
        ...dataFields.user_created,
    ];
    // const classMappings = {
    //     'gas_supply_building_status': 'col-1',
    //     'power_supply_building_status': 'col-1',
    //     'water_supply_building_status': 'col-1',
    //     'road_surface_building_status': 'col-1',
    //     'gas_supply_date_end': 'col-2',
    //     'power_supply_date_end': 'col-2',
    //     'water_supply_date_end': 'col-2',
    //     'road_surface_date_end': 'col-2',
    //     'commercial_object': 'col-3',
    //     'social_object': 'col-3',
    // };

    const classContin = {
        'date_start': 'col-1',
        'date_end': 'col-2',
    };

    return (
        <div className="continerPage">
            {
                isErr &&
                <ErrorMessage
                    message={isErr}
                    onClose={() => setIsErr(false)}
                    type={'error'}
                    onClick={() => {
                        saveReport()
                    }}
                />
            }
     
            <div className="inputContainerCol">
                {dataOption.map((item, index) => {
                             const key = item.key;
                             const value = item.value;
         
                             if (key === 'id' || key === 'coordinates' || renderedKeys.includes(key)) {
                                 return null;
                             }
                             renderedKeys.push(key);

                             const sortedOptions = value?.choices?.slice().sort((a, b) => a.display_name.localeCompare(b.display_name));
                             if(key === 'fields'){
                                return (
                                    <Fragment>
                                        {fields.map((field, index) => {
                                            return  (
                                                <div className={classContin[field.key] || 'col-3'} key={index}>
                                                    <div className={classContin[field.key] || 'col-3'}>
                                                            <FormInput
                                                                subInput={field.value.label}
                                                                type={field.value.type}
                                                                keyData={field.key}
                                                                onChange={(e) => handleChangeInput(key, e)}
                                                                required={false}
                                                                value={reportData[field.key]}
                                                                error={isErr}
                                                                style={'w-100'}
                                                                options={sortedOptions}
                                                                buttonInput={true}
                                                                placeholder={'Выберите поле… '}
                                                                icoButton={icons.TrashRed}
                                                                onChangeButton={()=>handleRemoveField(index)}
                                                                styleButton={'deleteButtonInput'}
                                                            />
                                                          
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    <Buttons text='Добавить поле' onClick={handleAddField} className={'addInputButton'}/>
                                    </Fragment>
                                )
                             }
         
                             return (
                                 <div className={classContin[key] || 'col-3'} key={index}>
                                    <div>
                                        <FormInput
                                            subInput={ key === 'fields' ? `Поле ${index}` : value.label}
                                            type={value.type}
                                            options={value.choices}
                                            keyData={key}
                                            onChange={(e) => handleChangeInput(key, e)}
                                            required={value.required}
                                            key={index}
                                            value={reportData[key]}
                                            error={isErr}
                                        />
                                    
                                    </div>
                                 </div>
                                 
                             );
                })}
               
            </div>
          

                <Buttons
                    text={'Сформировать отчет'}
                    className={'saveButton'}
                    ico={icons.floppyCheckmark}
                    onClick={() => {
                        saveReport()
                    }}
                />

        </div>
    );
};

export default ReportContain;
