import React, { Fragment, useEffect, useMemo, useState } from 'react'
import Header from '../../component/Header/Header'
import icons from '../../assets/icons/icons'
import Tables from '../../component/Tables/Tables'
import { fieldToArray } from "../../component/UI/functions/functions";
import LandPlot from "../../api/Landplot/LandPlot";
import { Link } from 'react-router-dom';
import Buttons from '../../component/Buttons/Buttons';
import LandPlotApiRequest from '../../api/Landplot/LandPlot';
import './styles.scss'
import Modal from '../../component/Modal/Modal';
import ToggleButton from '../../component/ToggleButton/ToggleButton';
import FilePicker from '../../component/UI/FilePicker/FilePicker';
import FormInput from '../../component/FormInput/FormInput';
import { updateDataKey } from '../../component/UI/functions/updateDataKey/updateDataKey';
import FileAttached from '../../component/UI/FileAttached/FileAttached';
import { useDispatch, useSelector } from 'react-redux';
import { cleardataLandploatFilter, setDataLandploatFilterPressItem } from '../../store/filterLandploatDataReduces'
import { setLoading } from '../../store/loadingReducer';
import ContentLoader from '../../component/ContentLoader/ContentLoader';
import Loader from '../../component/Loader/Loader';
import ContragentApiRequest from '../../api/Contragent/Contragent';
import groupUser from '../../locales/groupUser';

const LandPlotsTablePage = () => {

    const userData = useSelector(state => state.user_data.userData);

    const isAdmin = userData?.user?.groups?.some(group => group.name === groupUser.admin);
    const isCadastral = userData?.user?.groups?.some(group => group.name === groupUser.cadastralGroup);
    const isControlBuild = userData?.user?.groups?.some(group => group.name === groupUser.controlBuild);
    const isPaymentDepartment = userData?.user?.groups?.some(group => group.name === groupUser.PaymentDeportament);

    const [isList, setIsList] = useState()
    const [isFilterOption, setIsFilterOption] = useState()
    const [isMicrodistrictsOptions, setIsMicrodistrictsOptions] = useState()
    const [isMicrodistrictsOptionsCreate, setIsMicrodistrictsOptionsCreate] = useState()
    const [optionsSubStatus, setOptionsSubStatus] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false)
    const [isFilter, setIsFilter] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [isData, setIsData] = useState()
    const [isModal, setIsModal] = useState(false)
    const [isActiveMicrodist, setIsActiveMicrodist] = useState()
    const [isDishardLandploat, setIsDishardLandploat] = useState({});
    const [isDishardLandploatType, setIsDishardLandploatType] = useState({});
    const [textSearch, setTextSearch] = useState('');
    const [isLoadingButton, setIsLoadingButton] = useState(true);
    const [offset, setOffset] = useState(0);
    const [selectedToggle, setSelectedToggle] = useState(null);

    const dataLandploatFilter = useSelector((state) => state.data_landploatFilter);
    const dispatch = useDispatch();
    const loading = useSelector(state => state.loading);

    const contragentApi = new ContragentApiRequest();
    const [contragentData, setContragentData] = useState([])
    const [isNextUsers, setIsNextUsers] = useState('')
    const next = isData?.next?.slice(isData.next.indexOf('?') + 1);

    const handleToggleClick = (text) => {
        setSelectedToggle(text);
    };

    const landPlot = new LandPlotApiRequest();

    useEffect(() => {
        dispatch(setLoading(true));
        // clearFilter();
        landPlot.options().then((resp) => {
            if (resp.success) {
                setIsList(fieldToArray(resp?.data?.actions.list));
                setIsMicrodistrictsOptions(resp?.data?.actions.discharge);
                setIsMicrodistrictsOptionsCreate(resp?.data?.actions.create);
                setIsFilterOption(resp?.data?.actions?.filter);

                setIsDishardLandploat((prevIsDishardLandploat) => ({
                    ...prevIsDishardLandploat,
                    ['microdistrict_id']: 1
                }))

                const searchParams = new URLSearchParams(window.location.search);

                // Извлечь значения limit и offset из строки запроса
                const offset = searchParams.get('offset');
                const search = searchParams.get('search');

                const queryString = Object.keys(dataLandploatFilter)
                    .map((key) => `${key}=${dataLandploatFilter[key]}`)
                    .join('&');

                landPlot.list({ urlParams: `?limit=${Number(offset) + 25}&${queryString}&search=${search ? search : ''}` }).then((resp) => {
                    if (resp.success) {
                        // if (Object.keys(dataLandploatFilter).length !== 0) {
                        //     // `dataLandploatFilter` является пустым объектом
                        //     filterItems();
                        //   } else {
                        //     clearFilter();
                        //   }
                        setIsData(resp.data);
                        dispatch(setLoading(false));
                        setIsLoading(false)
                    } else {
                        return;
                    }
                });

            } else {
                return;
            }
        });


    }, [isUpdate]);

    const dischardLandploats = () => {
        const dishardFormData = new FormData()
        dishardFormData.append('microdistrict_id', isDishardLandploat.microdistrict_id);
        dishardFormData.append('landplot_type', isDishardLandploatType);
        isDishardLandploat.files.forEach((file, index) => {
            dishardFormData.append(`files${index}`, file);

        });

        landPlot.dischargeLandploats(dishardFormData).then((resp) => {

            console.log('Data from server:', resp);

            if (resp.success && resp.data) {
                // Преобразуем бинарную строку данных в Uint8Array
                const byteArray = Uint8Array.from(resp.data, char => char.charCodeAt(0)); // Преобразование строки в байты
                const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Создаем ссылку на скачивание файла
                const url = window.URL.createObjectURL(blob);
                console.log({ url });

                // Скачивание файла без создания <a>
                const link = document.createElement('a');
                link.href = url;
                link.download = 'file.xlsx'; // Имя файла
                document.body.appendChild(link);
                link.click();

                // Удаляем ссылку и освобождаем URL-объект
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                console.error('Error: No data or response not successful');
            }


            dispatch(setLoading(true));
            if (resp.success) {
                setIsUpdate(true)
                setIsModal(false)
                setIsLoading(false)
            } else {
                setIsUpdate(true)
                setIsModal(false)
                setIsLoading(false)
            }
        })
    }

    const loadMoreItems = () => {
        setIsLoadingButton(false);

        const offset = new URLSearchParams(isData.next).get('offset');

        landPlot.list(Object.keys(dataLandploatFilter).length !== 0 ? { urlParams: `?${next}` } : { urlParams: `?offset=${offset}` }).then((resp) => {
            if (resp.success) {
                setIsData((prevData) => ({
                    ...prevData,
                    results: [...prevData.results, ...resp.data.results],
                    next: resp.data.next,
                    count: resp.data.count,
                    previous: resp.data.previous
                }));
                // Сохранить данные пагинации в строке браузера
                const newUrl = window.location.pathname + `?offset=${offset}`;
                window.history.pushState({ path: newUrl }, '', newUrl);
            }
        });
    };

    useEffect(() => {

        next != null ? setIsLoadingButton(true) : setIsLoadingButton(false)
    }, [isData])


    const filterItems = () => {
        dispatch(setLoading(true));
        const queryParams = fieldToArray(dataLandploatFilter).map(item => `${item.key}=${item.value}`).join('&');
        const urlParams = `?${queryParams}`;

        landPlot.list({ urlParams }).then(resp => {
            if (resp.success) {
                setIsData((prevData) => ({
                    ...prevData,
                    results: resp.data.results,
                    next: resp.data.next,
                    count: resp.data.count,
                    previous: resp.data.previous
                }));
                dispatch(setLoading(false));
                setIsFilter(false);
            }
        });
    };

    const shouldShowLoadMoreButton = () => {
        return isData?.results?.length >= 0 && isData?.results?.length < isData?.count;
    };

    const clearFilter = () => {
        dispatch(cleardataLandploatFilter())
        setIsUpdate(true)
        setIsFilter(false)
    }

    const onSearchContragent = (e) => {
        contragentApi.searchContragent(e).then((resp) => {
            if (resp.success) {
                setContragentData(resp.data.results.map((item) => ({ display_name: item.org_name !== null ? item.org_name : item.fio, value: item.id })));
                setIsNextUsers(resp.data.next);
            }
        })
    };

    const loadMoreContragent = () => {
        if (isNextUsers !== null && !loading) {
            setLoading(true);
            contragentApi.NextContragent(isNextUsers).then((resp) => {
                if (resp.success) {

                    setContragentData((prevContragent) => [
                        ...prevContragent,
                        ...resp.data.results.map((item) => ({ display_name: item.org_name !== null ? item.org_name : item.fio, value: item.id }))
                    ]);
                    setIsNextUsers(resp.data.next);
                    setLoading(false);

                }
            })
        }
    };

    useEffect(() => {
        localStorage.setItem('filterLanploat', JSON.stringify(dataLandploatFilter))
    }, [dataLandploatFilter])


    const handleSearch = () => {
        dispatch(setLoading(true));
        landPlot.list({ urlParams: `?search=${textSearch}` }).then((resp) => {
            if (resp.success) {
                setIsData((prevData) => ({
                    ...prevData,
                    results: resp.data.results,
                    next: resp.data.next,
                    count: resp.data.count,
                    previous: resp.data.previous
                }));
                dispatch(setLoading(false));
                setIsLoading(false)
                // Сохранить данные пагинации в строке браузера
                const newUrl = window.location.pathname + `?search=${textSearch}`;
                window.history.pushState({ path: newUrl }, '', newUrl);
            }

        })
    }

    useMemo(() => {
        loadMoreContragent();
    }, []);


    return (
        <>
            {
                loading ?
                    <Loader />
                    :
                    <div>
                        {
                            isModal &&
                            <Modal
                                content={
                                    isLoading ?
                                        <ContentLoader />
                                        :
                                        <div className='containerModal'>
                                            <h1>Импорт участков</h1>
                                            {/* <p>Выберите один из форматов файлов импорта участков</p>

                                <div className='modalToggle'>
                                    <ToggleButton
                                        text={'SHP и DBF'}
                                        checked={selectedToggle === 'SHP и DBF'}
                                        onToggle={(e) => { handleToggleClick('SHP и DBF') }}
                                    />
                                    <ToggleButton
                                        text={'KML'}
                                        checked={selectedToggle === 'KML'}
                                        onToggle={(e) => { handleToggleClick('KML') }}
                                    />
                                </div> */}

                                            <FormInput
                                                subInput={'Микрорайон'}
                                                value={isActiveMicrodist}
                                                options={isMicrodistrictsOptions?.microdistrict_id?.choices}
                                                onChange={(e) => {
                                                    setIsDishardLandploat((prevIsDishardLandploat) => ({
                                                        ...prevIsDishardLandploat,
                                                        ['microdistrict_id']: e
                                                    }))
                                                }}

                                            />
                                            <FormInput
                                                subInput={'Тип участков'}
                                                value={isActiveMicrodist}
                                                options={isMicrodistrictsOptionsCreate?.type?.choices}
                                                onChange={(e) => {
                                                    setIsDishardLandploatType(e)
                                                }}

                                            />

                                            <FilePicker
                                                isUpload={true}
                                                type={'shape'}
                                                setFiles={(e) => {
                                                    setIsDishardLandploat((prevIsDishardLandploat) => ({
                                                        ...prevIsDishardLandploat,
                                                        ['files']: e
                                                    }))
                                                }}
                                            />
                                            {
                                                isDishardLandploat?.files &&
                                                <FileAttached
                                                    filesData={isDishardLandploat?.files}
                                                    addFile={false}
                                                />
                                            }


                                            <div className='modalButtonContainer'>
                                                <Buttons text={'Создать'} onClick={() => { dischardLandploats() }} />
                                                <Buttons text={'Отменить'} className={'greyButton'} onClick={() => { setIsModal(false) }} />

                                            </div>
                                        </div>
                                }
                                onClose={() => !isLoading && setIsModal(false)}
                            />
                        }
                        {
                            isFilter &&
                            <Modal
                                content={
                                    <div className='containerModal filter'>
                                        <h1>Фильтры</h1>
                                        {
                                            fieldToArray(isFilterOption).map((item) => {

                                                // if (item.key === 'sub_status') {
                                                //     if((dataLandploatFilter['status'] === '' || dataLandploatFilter['status'] === undefined) || optionsSubStatus.length === 0){
                                                //        return null
                                                //     }
                                                // }

                                                return (
                                                    <FormInput
                                                        subInput={item.value.label}
                                                        value={dataLandploatFilter[item.key]}
                                                        options={item?.value?.choices
                                                            ? !isAdmin
                                                                ? item.value.choices.filter(data => data.value !== 13 && data.display_name !== "Инвентаризация")
                                                                : item.value.choices
                                                            : item.key === 'contragent'
                                                                ? contragentData.length > 0 ? contragentData : [{}]
                                                                : ''}

                                                        onChange={(e) => { dispatch(setDataLandploatFilterPressItem([item.key, e])); }}
                                                        onScroll={loadMoreContragent}
                                                        onSearch={(e) => onSearchContragent(e)}
                                                        type={item.value.type}
                                                    />
                                                )
                                            })
                                        }


                                        <div className='modalButtonContainer'>
                                            <Buttons text={'Принять'} onClick={() => { filterItems() }} />
                                            <Buttons text={'Сбросить'} className={'greyButton'} onClick={() => { clearFilter() }} />
                                        </div>
                                    </div>
                                }
                                onClose={() => setIsFilter(false)}
                            />
                        }
                        <Header
                            name={'Участки и сети'}
                            icon={icons.map}
                            buttonContent={
                                // <div className='containerHeaderSearch'>
                                <>
                                    <FormInput
                                        value={textSearch}
                                        onChange={(e) => { setTextSearch(e) }}
                                        placeholder={'Введите текст для поиска'}
                                        style={'searchInput'}
                                        buttonInput
                                        icoButton={icons.search}
                                        styleButton={'buttonSearch'}
                                        onChangeButton={() => { handleSearch() }}
                                    />


                                    <div className='regionContainer'>
                                        <Buttons
                                            circle={fieldToArray(dataLandploatFilter).length > 0 && fieldToArray(dataLandploatFilter).length}
                                            ico={icons.Filter}
                                            text={'Фильтры'}
                                            className={'filterButton'}
                                            onClick={() => setIsFilter(true)}
                                        />

                                            <Fragment>
                                                <Buttons
                                                    text={'Импорт участков'}
                                                    onClick={() => setIsModal(true)}
                                                />
                                                <Link to={'/landplot'}>
                                                    <Buttons
                                                        text={'Добавить участок'}
                                                    />
                                                </Link>
                                            </Fragment>

                                    </div>
                                </>
                            }
                            lenght={isData?.count}
                        />
                        {

                            <Tables isData={isData?.results} next={isData?.next} isList={isList} type={'landplot'} loading={loading} isLoadingButton={isLoadingButton} onLoad={() => { shouldShowLoadMoreButton() && (loadMoreItems()) }} />
                        }

                    </div>
            }
        </>

    )
}

export default LandPlotsTablePage