// config/routesConfig.js

import icons from "../assets/icons/icons";
import ClientsTablePage from "../pages/ClientsTablePage/ClientsTablePage";
import ConstructionReportCreatePage from "../pages/ConstructionReportCreatePage/ConstructionReportCreatePage";
import ConstructorReportTablePage from "../pages/ConstructorReportTablePage/ConstructorReportTablePage";
import ControlConstructTablePage from "../pages/ControlConstructTablePage/ControlConstructTablePage";
import ErrorNetworkPage from "../pages/ErrorNetworkPage/ErrorNetworkPage";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import GeoObjectCreatePage from "../pages/GeoObjectCreatePage/GeoObjectCreatePage";
import GeoObjectTablePage from "../pages/GeoObjectTablePage/GeoObjectTablePage";
import IntegrationTablePage from "../pages/IntegrationTablePage/IntegrationTablePage";
import LandPlotsTablePage from "../pages/LandPlotsTablePage/LandPlotsTablePage";
import LandplotPage from "../pages/LandplotPage/LandplotPage";
import LandplotPageUpdate from "../pages/LandplotPageUpdate/LandplotPageUpdate";
import MapPage from "../pages/MapPage/MapPage";
import MicrodistrictPage from "../pages/MicrodistrictPage/MicrodistrictPage";
import MicrodistrictTablePage from "../pages/MicrodistrictTablePage/MicrodistrictTablePage";
import {MicrodistrictReportsPage} from "../pages/MicrodistrictReportsPage";

//Новая версия роутинга, пишем сайдбар для группы и указываем какие страницы нужны
//Имена групп должны быть чётко по админке/коллективу

const routesConfig = {
  'Admin': {
    sidebar: [
      { display: 'Микрорайоны', ico: icons.markerPin, path: '/' },
      { display: 'Участки', ico: icons.map, path: '/landPLots' },
      { display: 'Карта', ico: icons.map1, path: '/map' },
      { display: 'Контроль строительства', ico: icons.home, path: '/controls' },
      { display: 'Карта контроль строительства', ico: icons.map1, path: '/controlsMap' },
      { display: 'Клиенты', ico: icons.client, path: '/clients' },
      { display: 'Конструктор отчетов', ico: icons.fileConstruction, path: '/construction-report' },
      { display: 'Объекты', ico: icons.power, path: '/geoobjects' },
      { display: 'Карта объектов', ico: icons.fingerprint, path: '/object-map' },
      { display: 'Интеграции', ico: icons.shuffle, path: '/integration' },

    ],
    routes: [
      { path: '/', exact: true, component: MicrodistrictTablePage },
      { path: '/microdistrict-reports', exact: true, component: MicrodistrictReportsPage },
      { path: '/clients', exact: true, component: ClientsTablePage },
      { path: '/controls', exact: true, component: ControlConstructTablePage },
      { path: '/landplots', exact: true, component: LandPlotsTablePage },
      { path: '/landplot/:id', exact: true, component: LandplotPageUpdate, props: { toBack: '/landplots' } },
      { path: '/landplot', exact: true, component: LandplotPage },
      { path: '/microdistrict/:id', exact: true, component: MicrodistrictPage },
      { path: '/microdistrict', exact: true, component: MicrodistrictPage },
      { path: '/construction-report-create', exact: true, component: ConstructionReportCreatePage },
      { path: '/construction-report', exact: true, component: ConstructorReportTablePage },
      { path: '/geoobjects', exact: true, component: GeoObjectTablePage },
      { path: '/geoobject-create', exact: true, component: GeoObjectCreatePage },
      { path: '/geoobject/:id', exact: true, component: GeoObjectCreatePage },
      { path: '/control/:id', exact: true, component: LandplotPageUpdate, props: { type: 'control', toBack: '/controls' } },
      { path: '/object-map', exact: true, component: MapPage },
      { path: '/controlsMap', exact: true, component: MapPage },
      { path: '/map', exact: true, component: MapPage },
      { path: '/integration', exact: true, component: IntegrationTablePage },
      { path: '/errorNetwork', component: ErrorNetworkPage },
      { path: '/error', component: ErrorPage },
    ]
  },

  'GEO': {
    sidebar: [
      { display: 'Микрорайоны', ico: icons.markerPin, path: '/' },
      { display: 'Участки', ico: icons.map, path: '/landPLots' },
      { display: 'Карта', ico: icons.map1, path: '/map' },
      { display: 'Контроль строительства', ico: icons.home, path: '/controls' },
      { display: 'Карта контроль строительства', ico: icons.map1, path: '/controlsMap' },
      { display: 'Клиенты', ico: icons.client, path: '/clients' },
      { display: 'Конструктор отчетов', ico: icons.fileConstruction, path: '/construction-report' },
      { display: 'Объекты', ico: icons.power, path: '/geoobjects' },
      { display: 'Карта объектов', ico: icons.fingerprint, path: '/object-map' },
      { display: 'Интеграции', ico: icons.shuffle, path: '/integration' },

    ],
    routes: [
      { path: '/', exact: true, component: MicrodistrictTablePage },
      { path: '/microdistrict-reports', exact: true, component: MicrodistrictReportsPage },
      { path: '/clients', exact: true, component: ClientsTablePage },
      { path: '/controls', exact: true, component: ControlConstructTablePage },
      { path: '/landplots', exact: true, component: LandPlotsTablePage },
      { path: '/landplot/:id', exact: true, component: LandplotPageUpdate, props: { toBack: '/landplots' } },
      { path: '/landplot', exact: true, component: LandplotPage },
      { path: '/microdistrict/:id', exact: true, component: MicrodistrictPage },
      { path: '/microdistrict', exact: true, component: MicrodistrictPage },
      { path: '/construction-report-create', exact: true, component: ConstructionReportCreatePage },
      { path: '/construction-report', exact: true, component: ConstructorReportTablePage },
      { path: '/geoobjects', exact: true, component: GeoObjectTablePage },
      { path: '/geoobject-create', exact: true, component: GeoObjectCreatePage },
      { path: '/geoobject/:id', exact: true, component: GeoObjectCreatePage },
      { path: '/control/:id', exact: true, component: LandplotPageUpdate, props: { type: 'control', toBack: '/controls' } },
      { path: '/object-map', exact: true, component: MapPage },
      { path: '/controlsMap', exact: true, component: MapPage },
      { path: '/map', exact: true, component: MapPage },
      { path: '/integration', exact: true, component: IntegrationTablePage },
      { path: '/errorNetwork', component: ErrorNetworkPage },
      { path: '/error', component: ErrorPage },
    ]
  },

  // 'Cadastral_group': {
  //   sidebar: [
  //     { display: 'Микрорайоны', ico: icons.markerPin, path: '/' },
  //     { display: 'Участки', ico: icons.map, path: '/landPLots' },
  //     { display: 'Карта', ico: icons.map1, path: '/map' },
  //   ],
  //   routes: [
  //     { path: '/', exact: true, component: MicrodistrictTablePage },
  //     { path: '/landplots', exact: true, component: LandPlotsTablePage },
  //     { path: '/landplot/:id', exact: true, component: LandplotPageUpdate, props: { type: 'payment', toBack: '/landplots' } },
  //     { path: '/landplot', exact: true, component: LandplotPage },
  //     { path: '/microdistrict/:id', exact: true, component: MicrodistrictPage },
  //     { path: '/microdistrict', exact: true, component: MicrodistrictPage },
  //     { path: '/map', exact: true, component: MapPage },
  //     { path: '/errorNetwork', component: ErrorNetworkPage },
  //     { path: '/error', component: ErrorPage },
  //   ]
  // },
  //
  // 'auditor': {
  //   sidebar: [
  //     { display: 'Микрорайоны', ico: icons.markerPin, path: '/' },
  //     { display: 'Участки', ico: icons.map, path: '/landPLots' },
  //     { display: 'Карта', ico: icons.map1, path: '/map' },
  //     { display: 'Контроль строительства', ico: icons.home, path: '/controls' },
  //     { display: 'Карта контроль строительства', ico: icons.map1, path: '/controlsMap' },
  //     { display: 'Клиенты', ico: icons.client, path: '/clients' },
  //     { display: 'Конструктор отчетов', ico: icons.fileConstruction, path: '/construction-report' },
  //     { display: 'Объекты', ico: icons.power, path: '/geoobjects' },
  //     { display: 'Карта объектов', ico: icons.fingerprint, path: '/object-map' },
  //   ],
  //   routes: [
  //     { path: '/', exact: true, component: MicrodistrictTablePage },
  //     { path: '/clients', exact: true, component: ClientsTablePage },
  //     { path: '/controls', exact: true, component: ControlConstructTablePage },
  //     { path: '/landplots', exact: true, component: LandPlotsTablePage },
  //     { path: '/landplot/:id', exact: true, component: LandplotPageUpdate, props: { toBack: '/landplots' } },
  //     { path: '/landplot', exact: true, component: LandplotPage },
  //     { path: '/microdistrict/:id', exact: true, component: MicrodistrictPage },
  //     { path: '/microdistrict', exact: true, component: MicrodistrictPage },
  //     { path: '/construction-report-create', exact: true, component: ConstructionReportCreatePage },
  //     { path: '/construction-report', exact: true, component: ConstructorReportTablePage },
  //     { path: '/geoobjects', exact: true, component: GeoObjectTablePage },
  //     { path: '/geoobject-create', exact: true, component: GeoObjectCreatePage },
  //     { path: '/geoobject/:id', exact: true, component: GeoObjectCreatePage },
  //     { path: '/control/:id', exact: true, component: LandplotPageUpdate, props: { type: 'control', toBack: '/controls' } },
  //     { path: '/object-map', exact: true, component: MapPage },
  //     { path: '/controlsMap', exact: true, component: MapPage },
  //     { path: '/map', exact: true, component: MapPage },
  //     { path: '/errorNetwork', component: ErrorNetworkPage },
  //     { path: '/error', component: ErrorPage },
  //   ]
  // },
  //
  // 'ngeo_client_view_only': {
  //   sidebar: [
  //     { display: 'Клиенты', ico: icons.client, path: '/clients' },
  //   ],
  //   routes: [
  //     { path: '/clients', exact: true, component: ClientsTablePage },
  //     { path: '/errorNetwork', component: ErrorNetworkPage },
  //     { path: '/error', component: ErrorPage },
  //   ]
  // },
  //
  // 'СapitalСonstruction': {
  //   sidebar: [
  //     { display: 'Микрорайоны', ico: icons.markerPin, path: '/' },
  //     { display: 'Участки', ico: icons.map, path: '/landPLots' },
  //     { display: 'Контроль строительства', ico: icons.home, path: '/controls' },
  //     { display: 'Карта контроль строительства', ico: icons.map1, path: '/controlsMap' },
  //   ],
  //   routes: [
  //     { path: '/', exact: true, component: ControlConstructTablePage },
  //     { path: '/controls', exact: true, component: ControlConstructTablePage },
  //     { path: '/landplots', exact: true, component: LandPlotsTablePage },
  //     { path: '/landplot/:id', exact: true, component: LandplotPageUpdate, props: { toBack: '/landplots' } },
  //     { path: '/landplot', exact: true, component: LandplotPage },
  //     { path: '/construction-report-create', exact: true, component: ConstructionReportCreatePage },
  //     { path: '/construction-report', exact: true, component: ConstructorReportTablePage },
  //     { path: '/control/:id', exact: true, component: LandplotPageUpdate, props: { type: 'control', toBack: '/controls' } },
  //     { path: '/controlsMap', exact: true, component: MapPage },
  //     { path: '/errorNetwork', component: ErrorNetworkPage },
  //     { path: '/error', component: ErrorPage },
  //   ]
  // },
  //
  // 'Exploitation': {
  //   sidebar: [
  //     { display: 'Микрорайоны', ico: icons.markerPin, path: '/' },
  //     { display: 'Участки', ico: icons.map, path: '/landPLots' },
  //     { display: 'Карта', ico: icons.map1, path: '/map' },
  //   ],
  //   routes: [
  //     { path: '/', exact: true, component: MicrodistrictTablePage },
  //     { path: '/landplots', exact: true, component: LandPlotsTablePage },
  //     { path: '/landplot/:id', exact: true, component: LandplotPageUpdate, props: { type: 'payment', toBack: '/landplots' } },
  //     { path: '/landplot', exact: true, component: LandplotPage },
  //     { path: '/microdistrict/:id', exact: true, component: MicrodistrictPage },
  //     { path: '/microdistrict', exact: true, component: MicrodistrictPage },
  //     { path: '/errorNetwork', component: ErrorNetworkPage },
  //     { path: '/error', component: ErrorPage },
  //   ]
  // },
  //
  // 'Payment_departament': {
  //   sidebar: [
  //     { display: 'Микрорайоны', ico: icons.markerPin, path: '/' },
  //     { display: 'Участки', ico: icons.map, path: '/landPLots' },
  //     { display: 'Карта', ico: icons.map1, path: '/map' },
  //     { display: 'Клиенты', ico: icons.client, path: '/clients' },
  //     { display: 'Конструктор отчетов', ico: icons.fileConstruction, path: '/construction-report' },
  //   ],
  //   routes: [
  //     { path: '/', exact: true, component: MicrodistrictTablePage },
  //     { path: '/clients', exact: true, component: ClientsTablePage },
  //     { path: '/landplots', exact: true, component: LandPlotsTablePage },
  //     { path: '/landplot/:id', exact: true, component: LandplotPageUpdate, props: { toBack: '/landplots' } },
  //     { path: '/landplot', exact: true, component: LandplotPage },
  //     { path: '/microdistrict/:id', exact: true, component: MicrodistrictPage },
  //     { path: '/microdistrict', exact: true, component: MicrodistrictPage },
  //     { path: '/construction-report-create', exact: true, component: ConstructionReportCreatePage },
  //     { path: '/construction-report', exact: true, component: ConstructorReportTablePage },
  //     { path: '/map', exact: true, component: MapPage },
  //     { path: '/errorNetwork', component: ErrorNetworkPage },
  //     { path: '/error', component: ErrorPage },
  //   ]
  // },
  //
  'BaseGroup': {
    routes: [
      { path: '/errorNetwork', component: ErrorNetworkPage },
      { path: '/error', component: ErrorPage },
    ]
  },
  //
  // 'GEO_microdistricts': {
  //   sidebar: [
  //     { display: 'Микрорайоны', ico: icons.markerPin, path: '/' },
  //   ],
  //   routes: [
  //     { path: '/', exact: true, component: MicrodistrictTablePage },
  //     { path: '/microdistrict/:id', exact: true, component: MicrodistrictPage },
  //     { path: '/microdistrict', exact: true, component: MicrodistrictPage },
  //
  //   ]
  // },
  // 'GEO_areas': {
  //   sidebar: [
  //     { display: 'Участки', ico: icons.map, path: '/landPLots' },
  //   ],
  //   routes: [
  //     { path: '/landplots', exact: true, component: LandPlotsTablePage },
  //     { path: '/landplot/:id', exact: true, component: LandplotPageUpdate, props: { toBack: '/landplots' } },
  //     { path: '/landplot', exact: true, component: LandplotPage },
  //
  //   ]
  // },
  // 'GEO_map': {
  //   sidebar: [
  //     { display: 'Карта', ico: icons.map1, path: '/map' },
  //   ],
  //   routes: [
  //     { path: '/map', exact: true, component: MapPage },
  //
  //   ]
  // },
  // 'GEO_construction_control': {
  //   sidebar: [
  //     { display: 'Контроль строительства', ico: icons.home, path: '/controls' },
  //   ],
  //   routes: [
  //     { path: '/controls', exact: true, component: ControlConstructTablePage },
  //     { path: '/control/:id', exact: true, component: LandplotPageUpdate, props: { type: 'control', toBack: '/controls' } },
  //
  //   ]
  // },
  // 'GEO_map_construction_control': {
  //   sidebar: [
  //     { display: 'Карта контроль строительства', ico: icons.map1, path: '/controlsMap' },
  //   ],
  //   routes: [
  //     { path: '/controlsMap', exact: true, component: MapPage },
  //     { path: '/control/:id', exact: true, component: LandplotPageUpdate, props: { type: 'control', toBack: '/controlsMap' } },
  //
  //   ]
  // },
  // 'GEO_clients': {
  //   sidebar: [
  //     { display: 'Клиенты', ico: icons.client, path: '/clients' },
  //   ],
  //   routes: [
  //     { path: '/clients', exact: true, component: ClientsTablePage },
  //     { path: '/landplot/:id', exact: true, component: LandplotPageUpdate, props: { toBack: '/clients' } },
  //   ]
  // },
  // 'GEO_reports_constructor': {
  //   sidebar: [
  //     { display: 'Конструктор отчетов', ico: icons.fileConstruction, path: '/construction-report' },
  //   ],
  //   routes: [
  //     { path: '/construction-report-create', exact: true, component: ConstructionReportCreatePage },
  //     { path: '/construction-report', exact: true, component: ConstructorReportTablePage },
  //   ]
  // },
  // 'GEO_objects': {
  //   sidebar: [
  //     { display: 'Объекты', ico: icons.power, path: '/geoobjects' },
  //   ],
  //   routes: [
  //     { path: '/geoobjects', exact: true, component: GeoObjectTablePage },
  //     { path: '/geoobject-create', exact: true, component: GeoObjectCreatePage },
  //     { path: '/geoobject/:id', exact: true, component: GeoObjectCreatePage },
  //   ]
  // },
  // 'GEO_objects_map': {
  //   sidebar: [
  //     { display: 'Карта объектов', ico: icons.fingerprint, path: '/object-map' },
  //   ],
  //   routes: [
  //     { path: '/object-map', exact: true, component: MapPage },
  //     { path: '/geoobject/:id', exact: true, component: GeoObjectCreatePage },
  //   ]
  // },
  // 'GEO_integration': {
  //   sidebar: [
  //     { display: 'Интеграции', ico: icons.shuffle, path: '/integration' },
  //   ],
  //   routes: [
  //     { path: '/integration', exact: true, component: IntegrationTablePage },
  //   ]
  // },

  // Добавить тут ниже новые группы...
};

export default routesConfig;
