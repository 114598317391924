import geosIco from './geosIco.svg'
import bikLogo from './bikLogo.svg'
import Checkmark from './Checkmark.svg'
import geosBik from './geosBik.svg'
import HelpCircle from './helpCircle.svg'
import AlertCircle from './AlertCircle.svg'
import Smileys from './Smileys.svg'
import fileCopy from './document-file-copy.svg'
import chevronDown from './chevronDown.svg'
import check from './check.svg'
import floppyCheckmark from './floppyCheckmark.svg'
import Pencil from './Pencil.svg'
import Close from './Close.svg'
import file from './file.svg'
import TrashOne from './TrashOne.svg'
import Arrow from './Arrow.svg'
import CheckCircle from './CheckCircle.svg'
import fileDownload from './fileDownload.svg'
import markerPin from './markerPin.svg'
import map from './map.svg'
import map1 from './map1.svg'
import xClose from './xClose.svg'
import lock from './lock.svg'
import ArrowBackCol from './ArrowBackCol.svg'
import alertCircle from './alert-circle.svg'
import Filter from './Filter.svg'
import refreshRotate from './refreshRotate.svg'
import xClosed from './xClosed.svg'
import dotsMenu from './dotsMenu.svg'
import loadingGeo from './loadingGeo.svg'
import backSideBar from './backSideBar.svg'
import logout from './logout.svg'
import Logomini from './Logomini.svg'
import home from './home.svg'
import ArrowBack from './ArrowBack.svg'
import dotsAnimate from './dotsAnimate.svg'
import penEdit from './penEdit.svg'
import client from './client.svg'
import search from './search-md.svg'
import fileConstruction from './fileConstruction.svg'
import download from './download-02.svg'
import clockTime from './clock-time.svg'
import TrashRed from './TrashRed.svg'
import power from './power.svg'
import fingerprint from './fingerprint.svg'
import shuffle from './shuffle.svg'
import arrowDown from './arrowDown.svg'
import TableCross from './table-cross.svg'
import TableCheck from './table-check.svg'
import TableTrash from './table-trash.svg'
import TablePencil from './table-pencil.svg'
import DocumentCursorSelect from './document-cursor-select.svg'


 const icons = {
    TableCross,
    TableCheck,
    TableTrash,
    TablePencil,
    geosIco,
    bikLogo,
    Checkmark,
    geosBik,
    AlertCircle,
    HelpCircle,
    Smileys,
    fileCopy,
    chevronDown,
    check,
    floppyCheckmark,
    Pencil,
    Close,
    file,
    TrashOne,
    Arrow,
    CheckCircle,
    fileDownload,
    markerPin,
    map,
    map1,
    xClose,
    lock,
    ArrowBackCol,
    alertCircle,
    Filter,
    refreshRotate,
    xClosed,
    dotsMenu,
    loadingGeo,
    backSideBar,
    logout,
    Logomini,
    home,
    ArrowBack,
    dotsAnimate,
    penEdit,
    client,
    search,
    fileConstruction,
    power,
    download,
    clockTime,
    TrashRed,
    fingerprint,
    shuffle,
    arrowDown,
    DocumentCursorSelect,
};
export default icons