import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setDataPressItem } from '../../store/microdistDataReduces';
import FormInput from '../FormInput/FormInput';
import { fieldToArray } from '../UI/functions/functions';
import './styles.scss'
import Buttons from '../Buttons/Buttons';
import icons from '../../assets/icons/icons';
import LandPlotApiRequest from '../../api/Landplot/LandPlot';
import { useParams } from 'react-router-dom';
import { setNeedsRerender } from '../../store/needsRerenderSlice';
import ErrorMessage from '../UI/ErrorMassage/ErrorMassage';
import RightSidebar from '../RightSidebar/RightSidebar';
import { updateDataKey } from '../UI/functions/updateDataKey/updateDataKey';
import Checkbox from '../Checkbox/Checkbox';

const LandploatFromPayment = ({ paymentData, paymentOptionIntermediate, paymentOptionNewPlot, paymentOptionOldPlot, paymentOptionDefaultPlot, type }) => {
    const dispatch = useDispatch()
        , microdistData = useSelector((state) => state.mocrodist_data)
        , needsRerender = useSelector((state) => state.needsRerender)

    const [formError, setFormError] = useState('')
        , [isComplete, setIsComplete] = useState(false)
        , [openRightBar, setOpenRightBar] = useState(false)
        , [rightBar, setRightBar] = useState(null)
        , [paymentField, setPaymentField] = useState(null)
        
        , landPlot = new LandPlotApiRequest()
        , { id } = useParams();

    const handleChangeInput = (field, value, identifier) => {
        const defaultPlotPayment = [
            "waste_disposal",
        ];
        const newPlotFields = [
            "initial_payment",
            "land_price",
            "network_market_value",
            "preferential_value",
            "remaining_payment",
        ];
        const oldPlotFields = [
            "land_market_networks",
            "engineering_networks",
            "land_market_value",
            "planned_waste_disposal",
            "land_price"
        ];
        const intermediateFields = [
            "installment_for_plot",
            "market_land_price",
            "planned_waste_disposal",
        ];

        const updatedData = { ...microdistData?.payment_tab };

        if (identifier === 'new_plot_payment') {
            if (newPlotFields.includes(field)) {
                const updatedNewPlotPayment = { ...updatedData.new_plot_payment };
                if (field === 'land_price') {
                    updateDataKey(updatedNewPlotPayment, [field], value === '' ? null : value);
                    updateDataKey(updatedNewPlotPayment, ['initial_payment'], Number(Number(value).toFixed(2) * 0.6).toFixed(2));
                    updateDataKey(updatedNewPlotPayment, ['remaining_payment'], Number(Number(value).toFixed(2) * 0.4).toFixed(2));
                    updatedData.new_plot_payment = updatedNewPlotPayment;
                } else {
                    updateDataKey(updatedNewPlotPayment, [field], value === '' ? null : value);
                    updatedData.new_plot_payment = updatedNewPlotPayment;
                }
            }
        } else if (identifier === 'intermediate_plot_payment') {
            if (intermediateFields.includes(field)) {
                const updatedIntermediate = { ...updatedData.intermediate_plot_payment };
                updateDataKey(updatedIntermediate, [field], value === '' ? null : value);
                updatedData.intermediate_plot_payment = updatedIntermediate;
            }
        } else if (identifier === 'old_plot_payment') {
            if (oldPlotFields.includes(field)) {
                const updatedOldPlotPayment = { ...updatedData.old_plot_payment };
                updateDataKey(updatedOldPlotPayment, [field], value === '' ? null : value);
                updatedData.old_plot_payment = updatedOldPlotPayment;
            }
        } else if (identifier === 'default_plot_payment') {
            if (defaultPlotPayment.includes(field)) {
                const updatedDefaultPlotPayment = { ...updatedData.default_plot_payment };
                updateDataKey(updatedDefaultPlotPayment, [field], value === '' ? null : value);
                updatedData.default_plot_payment = updatedDefaultPlotPayment;
            }
        } else {
            updateDataKey(updatedData, [field], value === '' ? null : value);
        }

        dispatch(setDataPressItem(['payment_tab', updatedData]));
    };

    const classContain = {
        'passport': 'col-1',
        'phone': 'col-1',
        'number': 'col-1',
        'birth_date': 'col-2',
        'email': 'col-2',
        'bonus_doc_date': 'col-2',
    };

    const paymentButtonKey = [
        'engineer_networks',
        'planned_waste_disposal',
        'waste_disposal',
        'remaining_payment',
        'initial_payment',
        'installment_for_plot',
        'engineering_networks',
        'initial_payment',
        'remaining_payment',
    ]
    const offVisibleInputKey = [
        'id',
        'tab',
        'engineering_networks_payments',
        'planned_waste_disposal_payments',
        'installment_for_plot_payments',
        'waste_disposal_payments',
        'remaining_payments',
        'initial_payments',
    ]
    const paymentKey = [
        'payments',
        "user"
    ]

    const optionPayment = [
        {
            key: 'id',
            value: {
                label: 'ID',
                read_only: true,
                required: false,
                type: 'string'
            }
        },
        {
            key: 'date',
            value: {
                label: 'Дата',
                read_only: true,
                required: false,
                type: 'string'
            }
        },
        {
            key: 'payment_amount',
            value: {
                label: 'Платеж',
                read_only: true,
                required: false,
                type: 'string'
            }
        },
        {
            key: 'payment_type',
            value: {
                label: 'Тип',
                read_only: true,
                required: false,
                type: 'string'
            }
        },
        {
            key: 'note',
            value: {
                label: 'Примечание',
                read_only: true,
                required: false,
                type: 'string'
            }
        },
    ]

    const keysToCheck = ['default_plot_payment', 'new_plot_payment', 'old_plot_payment', 'intermediate_plot_payment'];

    // Функция для рендеринга полей формы
    const renderFormInputs = (dataOptions, identifier) => {

        return dataOptions.map((item, index) => {
            const key = item.key;

            if (offVisibleInputKey.includes(key)) {
                return null;
            }

            let foundValue = null;

            keysToCheck.some(checkKey => {
                const value = microdistData?.payment_tab?.[checkKey];
                if (value !== undefined && checkKey.includes(identifier)) { // Проверяем идентификатор
                    foundValue = value;
                    return  // Stop iteration once a value is found
                }
                return 
            });

            const inputProps = {
                subInput: item?.value?.label,
                type: item?.value?.type,
                options: item?.value?.choices,
                keyData: item?.key,
                onChange: (e) => handleChangeInput(item?.key, e, identifier),
                required: item?.value?.required,
                disabled: type === 'control' ? true : (item.key === 'initial_payment' || item.key === 'remaining_payment') && true,
                value: foundValue?.[item.key],

                tip: ((item.key === 'preferential_value' || item.key === 'market_land_price' || item.key === 'land_market_value') && 'Сумма возврата в случае неисполнения через 7 лет'),
            };

            const matchingKey = keysToCheck.find(kt => microdistData.payment_tab[kt]?.hasOwnProperty(key));
            const dataPayment = microdistData.payment_tab[matchingKey];
            const suffix = key.endsWith('payment') ? 's' : '_payments';
            const dataKey = `${key}${suffix}`;
            const dataHasItems = dataPayment?.hasOwnProperty(dataKey)

            return (
                <div className={classContain[key] || `col-3 ${paymentButtonKey.includes(key) && 'inputContainerButton'}`} key={index}>
                    <FormInput {...inputProps} />

                    {paymentButtonKey.includes(key) && (
                        <Buttons
                            text={'Платежи'}
                            className={`whiteButton col-2 ${dataHasItems ? '' : '_disable'}`}
                            onClick={() => {
                                if (dataHasItems) {
                                    // handleOpenRightBar(dataPayment[dataKey], item, identifier, foundValue);
                                    handleOpenRightBar(matchingKey, dataKey, item, identifier, foundValue);
                                }
                            }}
                        />
                    )}

                </div>
            );

        });
    };


    // Функция для обновления данных участка
    const updateDistrict = () => {
        let hasError = false;
        dispatch(setDataPressItem(['tab', 'payment_tab']));
        if (hasError) {
            setFormError("Заполните все обязательные поля.");
        } else {
            landPlot.update({ urlParams: id + '/', body: microdistData }).then((resp) => {
                if (resp.success) {
                    setIsComplete('Данные обновлены')
                }
                dispatch(setNeedsRerender(!needsRerender.needsRerender));
            });
        }
    };

    const handleOpenRightBar = (matchingKey, dataKey, option, identifier, field) => {
        const barData = {
            title: option?.value?.label,
            options: option,
            matchingKey: matchingKey,
            key: dataKey,
            paymentData: {...field, identifier: identifier}
        }
        setRightBar({...barData})
        setOpenRightBar(true)
    }

    return (
        <div className='continerLandplotPage'>
            {formError &&
                <ErrorMessage
                    type={'error'}
                    message={formError}
                    onClose={() => setFormError(false)}
                />
            }
            {
                isComplete &&
                <ErrorMessage
                    message={isComplete}
                    onClose={() => setIsComplete(false)}
                    type={'success'}
                />
            }

            <RightSidebar
                openRightBar={openRightBar}
                onClosed={() => { setOpenRightBar(!openRightBar); setRightBar(null) }}
                isList={optionPayment}
                barData={rightBar}
            />

            <div className='inputContainerColPayment'>
                <div className='inputContainerCol'>
                    {paymentOptionDefaultPlot && renderFormInputs(paymentOptionDefaultPlot.filter((item) => !paymentKey.includes(item.key)), 'default_plot_payment')}
                </div>
                <div className='inputContainerCol'>
                    <p className='inputContainerColTitle col-3'>Новые участки <span>3 августа 2023 — настоящее время</span></p>
                    {paymentOptionNewPlot && renderFormInputs(paymentOptionNewPlot.filter((item) => !paymentKey.includes(item.key)), 'new_plot_payment')}
                </div>
                <div className='inputContainerCol'>
                    <p className='inputContainerColTitle col-3'>Промежуточные участки <span>6 июня 2022 — 3 августа 2023</span></p>
                    {paymentOptionIntermediate && renderFormInputs(paymentOptionIntermediate.filter((item) => !paymentKey.includes(item.key)), 'intermediate_plot_payment')}
                </div>
                <div className='inputContainerCol'>
                    <p className='inputContainerColTitle col-3'>Старые участки <span>2004 год — 6 июня 2022</span></p>
                    {paymentOptionOldPlot && renderFormInputs(paymentOptionOldPlot.filter((item) => !paymentKey.includes(item.key)), 'old_plot_payment')}
                </div>
                {fieldToArray(paymentData).map((item) => {
                    if (item.key === 'is_free') {
                        return (
                            <Checkbox label={'Бесплатный участок'} checked={item.value} onChange={(e) => { }} />
                        )
                    }
                })}
            </div>
                <Buttons text={'Сохранить'} className={'saveButton'} ico={icons.floppyCheckmark} onClick={() => { updateDistrict() }} />
        </div>
    )
}

export default LandploatFromPayment