import React, { useEffect, useState } from 'react';
import Header from '../../component/Header/Header';
import icons from '../../assets/icons/icons';
import FormInput from '../../component/FormInput/FormInput';
import MicrodistrictContain from '../../component/MicrodistrictContain/MicrodistrictContain';
import { useLocation, useParams } from 'react-router-dom';
import HeaderInfo from '../../component/HeaderInfo/HeaderInfo';
import { useSelector } from 'react-redux';
import groupUser from '../../locales/groupUser';
import GeoObjectContain from '../../component/GeoObjectContain/GeoObjectContain';
import GeoObjectReportApiRequest from '../../api/geoobjectReport/geoobjectReport';

const GeoObjectCreatePage = () => {

    const location = useLocation();
    const body = location.state || {};
    const geoobjectReport = new GeoObjectReportApiRequest();
    const { id } = useParams();
    const [isData, setIsData] = useState(location.state || {})
    
    const userData = useSelector(state => state.user_data.userData);



    useEffect(()=>{
        geoobjectReport.getById({id:`${id}`}).then((resp)=>{
            if(resp.success){
                setIsData(resp.data)
            }
        })
    },[])

    return (
        <div>
            <HeaderInfo icon={icons.Arrow} name={isData?.name ? isData.name : 'Создание объекта'} />
            <GeoObjectContain data={isData} />
        </div>
    );
};

export default GeoObjectCreatePage;
