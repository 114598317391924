import React, {useState} from "react";
import Header from "../../../component/Header/Header";
import icons from "../../../assets/icons/icons";
import FormInput from "../../../component/FormInput/FormInput";
import apiConfig from "../../../api/apiConfig";
import '../styles/microdistrictReportsPage.scss'

const selectOptions = [
    {
        value: 'landplots/generate_agricultural_report',
        display_name: ' Отчетная форма по предоставлению информации о земельных участках сельскохозяйственного назначения'
    },
    {
        value: 'microdistrict/generate_report',
        display_name: 'Отчетная форма по предоставлению информации о земельных участках с ВРИ "ИЖС'
    },
]

export default function MicrodostroctReportsPage() {
    const startValue = selectOptions[0].value
    const [selectValue, setSelectValue] = useState(startValue)

    const onSelect = (data) => {
        setSelectValue(data)
    }

    return (
        <>
            <div className='conainerPage'>
                <Header
                    name={'Сводные отчеты'}
                    icon={icons.DocumentCursorSelect}
                />
                <div className={'microdistrict-reports-page-main-content'}>
                    <FormInput
                        value={startValue}
                        options={selectOptions}
                        required={true}
                        onChange={onSelect}
                    />

                    <a href={apiConfig.baseUrl + selectValue} className={'button__container'}>Скачать</a>
                </div>
            </div>
        </>

    );
}