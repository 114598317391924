import React, {useEffect, useRef, useState} from 'react';
import './styles.scss';
import FormInput from '../FormInput/FormInput';
import {fieldToArray} from '../UI/functions/functions';
import Buttons from '../Buttons/Buttons';
import icons from '../../assets/icons/icons';
import {useDispatch, useSelector} from 'react-redux';
import {
    clearDataMicrodist,
    setDataPressItem,
    clearDataMicrodistKey,
    clearDataNullMicrodistKey
} from '../../store/microdistDataReduces';
import LandPlot from '../../api/Landplot/LandPlot';
import {useParams} from 'react-router-dom';
import ContragentApiRequest from '../../api/Contragent/Contragent';
import FileAttached from '../UI/FileAttached/FileAttached';
import FormBlock from '../FormBlock/FormBlock';
import {updateDataKey} from '../UI/functions/updateDataKey/updateDataKey';
import {setNeedsRerender} from '../../store/needsRerenderSlice';
import ErrorMessage from '../UI/ErrorMassage/ErrorMassage';
import groupUser from '../../locales/groupUser';
import Loader from '../Loader/Loader';

const LandploatFormContragent = ({optionsContragent, optionsContract, type}) => {
    const landPlot = new LandPlot();
    const dispatch = useDispatch();
    const contragentApi = new ContragentApiRequest();
    const microdistData = useSelector((state) => state.mocrodist_data);
    const needsRerender = useSelector((state) => state.needsRerender);
    const {id} = useParams();
    const [formError, setFormError] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const contragentListRef = useRef();
    const [contragentData, setContragentData] = useState([])
    const [loading, setLoading] = useState(false);
    const [isNextUsers, setIsNextUsers] = useState('');


    const userData = useSelector(state => state.user_data.userData);

    const isAdmin = userData?.user?.groups?.some(group => group.name === groupUser.admin);
    const isControlBuild = userData?.user?.groups?.some(group => group.name === groupUser.controlBuild);

    // Определяем классы для столбцов формы на основе полей
    const classContain = {
        'passport': 'col-1',
        'phone': 'col-1',
        'number': 'col-1',
        'birth_date': 'col-2',
        'email': 'col-2',
        'bonus_doc_date': 'col-2',
    };


    useEffect(() => {
        dispatch(clearDataMicrodistKey(['contragent_tab']))
        const updatedContragent = updateDataKey(
            {...microdistData?.contragent_tab?.contragent},
            ['contragent'],
            microdistData?.contragent_tab?.contragent?.id
        );

        dispatch(setDataPressItem(['contragent_tab', {
            ...microdistData?.contragent_tab,
            contragent: updatedContragent
        }]));
    }, [isUpdate]);

    useEffect(() => {
        if (microdistData?.contragent_tab?.contragent?.contragent !== null) {
            contragentApi.getById({urlParams: microdistData?.contragent_tab?.contragent?.contragent}).then((resp) => {
                if (resp.success) {
                    setContragentData((prevContragent) => [
                        ...prevContragent,
                        {
                            display_name: resp.data.org_name !== null ? resp.data.org_name : resp.data.fio,
                            value: resp.data.id
                        }
                    ])
                }
            })

        }
    }, [isUpdate])

    const onSearchContragent = (e) => {
        contragentApi.searchContragent(e).then((resp) => {
            if (resp.success) {
                setContragentData(resp.data.results.map((item) => ({
                    display_name: item.org_name !== null ? item.org_name : item.fio,
                    value: item.id
                })));
                setIsNextUsers(resp.data.next);
            }
        })
    };

    const loadMoreContragent = () => {
        if (isNextUsers !== null && !loading) {
            setLoading(true);
            contragentApi.NextContragent(isNextUsers).then((resp) => {
                if (resp.success) {

                    setContragentData((prevContragent) => [
                        ...prevContragent,
                        ...resp.data.results.map((item) => ({
                            display_name: item.org_name !== null ? item.org_name : item.fio,
                            value: item.id
                        }))
                    ]);
                    setIsNextUsers(resp.data.next);
                    setLoading(false);
                }
            })
        }
    };

    useEffect(() => {
        loadMoreContragent();
    }, []);


    useEffect(() => {
        setIsUpdate(true)
    }, [])
    // Функция для обработки полей контрагента и контракта
    const handleContragentField = (field, value) => {
        const contragentFields = [
            "birth_date",
            "passport",
            "email",
            "field",
            "phone",
            "contacts",
            "ur_address",
            "www_password"
        ];
        const contractFields = [
            "bonus_doc_date",
            "bonus_doc_date_register",
            "bonus_doc_status",
            "bonus_doc_type",
            "protocol_number",
            "protocol_date",
            "extension_construction",
            "extension_construction_date",
            "files",
            "note",
            "number"
        ];

        const updatedData = {...microdistData?.contragent_tab};

        if (contragentFields.includes(field)) {
            const updatedContragent = {...updatedData.contragent};
            updateDataKey(updatedContragent, [field], value);
            updatedData.contragent = updatedContragent;
        } else if (contractFields.includes(field)) {
            const updatedContract = {...updatedData.contract};
            updateDataKey(updatedContract, [field], value);
            updatedData.contract = updatedContract;
        } else {
            updateDataKey(updatedData, [field], value);
        }

        dispatch(setDataPressItem(['contragent_tab', updatedData]));

    };


    // Функция для обработки изменения ввода
    const handleInputChange = (field, value) => {
        if (field === "contragent") {
            handleIdField(value);
        } else {
            handleContragentField(field, value);
        }
    };

    // Функция для обработки поля ID и загрузки данных контрагента
    const handleIdField = (value) => {
        setIsLoading(true);
        dispatch(clearDataMicrodistKey(['contragent_tab']))
        contragentApi.InfoContragent(value).then((resp) => {
            if (resp.success) {
                const updatedContragent = {...microdistData?.contragent_tab?.contragent};
                fieldToArray(resp.data).forEach((item) => {
                    // if (item.key === 'has_contract' && item.value === true) {
                    //     setFormError('У собственника есть договор')
                    // } else {
                    updatedContragent[item.key] = item.value;
                    // }

                });

                delete updatedContragent?.contragent;

                dispatch(setDataPressItem(['contragent_tab', {
                    ...microdistData?.contragent_tab,
                    contragent: updatedContragent
                }]));
            }
        });
    };

    // Функция для обновления данных участка
    const updateDistrict = () => {
        let hasError = false;
        dispatch(setDataPressItem(['tab', 'contragent_tab']));

        optionsContract.forEach((item) => {
            if (item.value.required && (!microdistData?.contragent_tab?.contract?.[item.key] || microdistData.contragent_tab.contract[item.key].length === 0)) {
                hasError = true;
            }
        });

        optionsContragent.forEach((item) => {
            if (item.value.required && (!microdistData?.contragent_tab?.contragent?.[item.key] || microdistData.contragent_tab.contragent[item.key].length === 0)) {
                hasError = true;
            }
        });


        if (hasError) {
            setFormError("Заполните все обязательные поля.");
        } else {
            landPlot.update({urlParams: id + '/', body: microdistData}).then((resp) => {
                if (resp.success) {
                    setIsComplete('Данные обновлены')
                }
                // } else {
                //     setFormError("У собственника есть договор");
                // }
                dispatch(setNeedsRerender(!needsRerender.needsRerender));
            });
        }
    };

    const deleteContragent = () => {
        landPlot.deleteContragentContract(id).then((resp) => {
            if (resp.success) {
                const updatedContragent = {...microdistData?.contragent_tab?.contragent};
                fieldToArray(resp.data).forEach((item) => {
                    updatedContragent[item.key] = item.value;

                });

                delete updatedContragent?.contragent;

                dispatch(setDataPressItem(['contragent_tab', {
                    ...microdistData?.contragent_tab,
                    contragent: updatedContragent
                }]));
                dispatch(setNeedsRerender(!needsRerender.needsRerender));
            }
        })
    }

    useEffect(() => {
        // Очищаем данные, если `id` не определен
        if (id === undefined) {
            dispatch(clearDataMicrodist());
        }
    }, [id]);


    const contactsDataResult = optionsContragent?.filter((itemData) => itemData.key == 'contacts')
        .reduce((acc, itemData) => {
            const commercialFields = fieldToArray(itemData.value.child.children);
            return [...acc, ...commercialFields];
        }, []);

    // Функция для рендеринга полей формы
    const renderFormInputs = (dataOptions, isContract = false) => {
        return dataOptions.map((item, index) => {
            const key = item.key;

            if (key === 'id' || key === 'tab' || key === 'contacts' || key === 'has_contract') {
                return null;
            }


            const inputProps = {
                ref: contragentListRef,
                subInput: item?.value?.label,
                type: item?.value?.type,

                options: item?.value?.choices
                    ? item.value.choices
                    : item.key === 'contragent'
                        ? contragentData.length > 0 ? contragentData : [{}]
                        : '',

                keyData: item?.key,
                onChange: (e) => handleInputChange(item?.key, e),
                onScroll: loadMoreContragent,
                onSearch: (e) => onSearchContragent(e),
                loadingScroll: loading,
                required: item?.value?.required,
                disabled: false,

                value: isContract
                    ? (microdistData?.contragent_tab?.contract?.hasOwnProperty(item?.key) ? microdistData?.contragent_tab?.contract?.[item?.key] : '')
                    : (microdistData?.contragent_tab?.contragent?.hasOwnProperty(item?.key) ? microdistData?.contragent_tab?.contragent?.[item?.key] : '')
            };

            if (key === 'files') {
                return (
                    <div className='col-3' key={index}>
                        <div className='fromBlockGray'>
                            <h1>{item?.value?.label}</h1>
                            <FileAttached
                                filesData={microdistData?.contragent_tab?.contract?.files}
                                setFiles={(e) => {
                                    handleInputChange('files', e)
                                }}
                                addFile={true}
                                onDelete={(e) => {
                                    handleInputChange('files', microdistData?.contragent_tab?.contract?.files.filter(file => file.id !== e.id))
                                }}
                            />
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className={classContain[key] || 'col-3'} key={index}>
                        <FormInput {...inputProps} />
                    </div>
                );
            }
        });
    };

    return (
        <>
            <div className='continerLandplotPage'>
                {formError &&
                    <ErrorMessage
                        type={'error'}
                        message={formError}
                        onClose={() => setFormError(false)}
                    />
                }
                {
                    isComplete &&
                    <ErrorMessage
                        message={isComplete}
                        onClose={() => setIsComplete(false)}
                        type={'success'}
                    />
                }

                <div className='inputContainerCol'>
                    {isUpdate && optionsContragent && renderFormInputs(optionsContragent)}
                    {isUpdate && optionsContract && renderFormInputs(optionsContract, true)}
                    <div className={'col-3'}>
                        <h1>Дополнительные контакты</h1>
                        {optionsContragent?.map((item) => {
                            return item.key === 'contacts' &&
                                <FormBlock
                                    title={`Контакт`}
                                    dataOptions={contactsDataResult}
                                    onChange={(e) => handleInputChange('contacts', e)}
                                    initData={microdistData?.contragent_tab?.contragent?.contacts}
                                    textAddButton={'Добавить контакт'}
                                    disabled={type === 'control' && true}
                                />
                        })}
                    </div>
                </div>

                <Buttons text={'Сохранить'} className={'saveButton'} ico={icons.floppyCheckmark} onClick={() => {
                    updateDistrict()
                }}/>
                {isAdmin && <Buttons text={'Очистить собственника'} className={'deleteButton col-1'} onClick={() => {
                    deleteContragent()
                }}/>}
            </div>
        </>
    );
};

export default LandploatFormContragent;
