import React, { useEffect, useState } from 'react';
import Header from '../../component/Header/Header';
import icons from '../../assets/icons/icons';
import FormInput from '../../component/FormInput/FormInput';
import MicrodistrictContain from '../../component/MicrodistrictContain/MicrodistrictContain';
import { useLocation } from 'react-router-dom';
import HeaderInfo from '../../component/HeaderInfo/HeaderInfo';
import { useSelector } from 'react-redux';
import groupUser from '../../locales/groupUser';
import ReportContain from '../../component/ReportContain/ReportContain';

const ConstructionReportCreatePage = () => {

    const location = useLocation();
    const body = location.state || {};


    const userData = useSelector(state => state.user_data.userData);


    return (
        <div>
            <HeaderInfo icon={icons.Arrow} name={'Создание отчета'} />
            <ReportContain data={body ? body : undefined}  />
        </div>
    );
};

export default ConstructionReportCreatePage;
